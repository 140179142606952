const logoText = "DREW";

const introPhrases = {
  title: "OMG",
  animated: {
    first: "Birthday mo???",
    second: "Weh??!!! Di nga?",
    third: "Edi Happy Birthday!!!",
  },
  description: "I wish you all the best <3 I love you!",
  your_img_url:
    "https://drew-bday.s3.ap-southeast-1.amazonaws.com/IMG_0967.JPG",
};

const birthdayContent = [
  {
    img: "https://drew-bday.s3.ap-southeast-1.amazonaws.com/giorgio.jpg",
    desctiption: "Watch Birthday Video Greeting",
    link: "https://youtu.be/euQKJv6nRDU",
  },
  {
    img: "https://drew-bday.s3.ap-southeast-1.amazonaws.com/baysafam2.jpeg",
    desctiption: "Watch Birthday Video Greeting",
    link: "https://youtube.com/shorts/DVZuRK_A7FI?feature=share",
  },
  {
    img: "https://drew-bday.s3.ap-southeast-1.amazonaws.com/sam.jpeg",
    desctiption: "Watch Birthday Video Greeting",
    link: "https://youtube.com/shorts/EUgRx84CqSk?feature=share",
  },
  {
    img: "https://drew-bday.s3.ap-southeast-1.amazonaws.com/samian.jpeg",
    desctiption: "Watch Birthday Video Greeting",
    link: "https://youtube.com/shorts/6JKZbFCiRbw?feature=share",
  },
  {
    img: "https://drew-bday.s3.ap-southeast-1.amazonaws.com/alan.jpeg",
    desctiption: "Watch Birthday Video Greeting",
    link: "https://youtu.be/vLUn1WzeMeA",
  },
  {
    img: "https://drew-bday.s3.ap-southeast-1.amazonaws.com/ani.jpeg",
    desctiption: "Watch Birthday Video Greeting",
    link: "https://youtu.be/L4LmUN3nWSw",
  },

  {
    img: "https://drew-bday.s3.ap-southeast-1.amazonaws.com/aguilarfam.jpeg",
    desctiption: "Watch Birthday Video Greeting",
    link: "https://youtu.be/4Hf6C-m-0Jk",
  },
  {
    img: "https://drew-bday.s3.ap-southeast-1.amazonaws.com/maribel.jpeg",
    desctiption: "Watch Birthday Video Greeting",
    link: "https://youtube.com/shorts/94VFnMwXAdE?feature=share",
  },
  {
    img: "https://drew-bday.s3.ap-southeast-1.amazonaws.com/drewbday.JPG",
    desctiption:
      "October 19, 2022 Early Birthday Celebration @ Meister's Uncorked",
    link: "https://drew-bday.s3.ap-southeast-1.amazonaws.com/drewbday.JPG",
  },
  {
    img: "https://drew-bday.s3.ap-southeast-1.amazonaws.com/raine.jpeg",
    desctiption: "Watch Birthday Video Greeting",
    link: "https://youtu.be/QH8h0ri9yUY",
  },
  {
    img: "https://drew-bday.s3.ap-southeast-1.amazonaws.com/nicole.JPG",
    desctiption: "Watch Birthday Video Greeting",
    link: "https://youtube.com/shorts/ldQRBnEsuQs?feature=share",
  },
  // {
  //     img: "https://picsum.photos/400/700/?grayscale",
  //     desctiption: "The wisdom of life consists in the elimination of non-essentials.",
  //     link: "#",
  // },
];
export { birthdayContent, introPhrases, logoText };
