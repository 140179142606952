import React from "react";
import "./style.css";
import { Helmet, HelmetProvider } from "react-helmet-async";
import { Container, Row, Col } from "react-bootstrap";
import { birthdayContent } from "../../content_option";
import Headermain from "../../components/header";

export const Gallery = () => {
  const galleryTitle = "Gallery";

  return (
    <HelmetProvider>
      <Headermain />
      <Container className="About-header">
        <Helmet>
          <meta charSet="utf-8" />
          <title> {galleryTitle} </title>
        </Helmet>
        <Row className="mb-5 mt-3">
          <Col lg="8">
            <h1 className="display-4 mb-4"> Photos and Videos </h1>
            <hr className="t_border my-4 ml-0 text-left" />
          </Col>
        </Row>
        <div className="mb-5 po_items_ho">
          {birthdayContent.map((data, i) => {
            return (
              <div key={i} className="po_item">
                <img src={data.img} alt="" />
                <div className="content">
                  <p>{data.desctiption}</p>
                  <a href={data.link}>View</a>
                </div>
              </div>
            );
          })}
        </div>
      </Container>
    </HelmetProvider>
  );
};
