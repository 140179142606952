import AWS from 'aws-sdk';
import { CognitoUserPool } from 'amazon-cognito-identity-js';
// require('dotenv').config();

// console.log(process);

// Cognito
const USER_POOL_CREDENTIALS = {
    UserPoolId: 'ap-southeast-1_HpcFrV2xo',
    ClientId: '2r187ekecgtovjfkvghb13brj0',
};
const AUTH_CREDENTIALS = {
    region: process.env.REGION,
    apiVersion: process.env.API_VERSION,
    accessKeyId: process.env.ACCESS_KEY_ID,
    secretAccessKey: process.env.SECRET_ACCESS_KEY
};

export const CognitoIdentityServiceProvider = new AWS.CognitoIdentityServiceProvider(AUTH_CREDENTIALS);
export const UserPool = new CognitoUserPool(USER_POOL_CREDENTIALS);

