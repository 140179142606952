import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import "./index.css";
import "bootstrap/dist/css/bootstrap.css";
import { BrowserRouter } from "react-router-dom";
import { SnackbarProvider } from "notistack";
import AnimatedCursor from "react-animated-cursor";
import reportWebVitals from "./reportWebVitals";

ReactDOM.render(
  <BrowserRouter>
    <div className="cursor__dot">
      <AnimatedCursor
        innerSize={15}
        outerSize={15}
        color="0, 0 ,0"
        outerAlpha={0.4}
        innerScale={0.7}
        outerScale={5}
      />
    </div>
    <SnackbarProvider>
      <App />
    </SnackbarProvider>
  </BrowserRouter>,

  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
