import { Navigate, Outlet, useLocation } from "react-router-dom";
import useAuth from "./useAuth";

export const RequireAuth = () => {
  const { auth } = useAuth();
  const location = useLocation();

  if (!auth) {
    // user is not authenticated
    return <Navigate to="/login" state={{ fom: location }} replace />;
  }
  return <Outlet />;
};
