/**
 * ------------------------------------------------------------------------------------------
 * [COMPONENT]
 * @function AppLoadingIndicator  -   Loading screen
 * ------------------------------------------------------------------------------------------
 */

 import React from "react";

 import { Grid, CircularProgress } from "@mui/material";
 
 /**
  * A component to render a loading indicator
  */
 export default function AppLoadingIndicator() {
   return (
     <Grid
       container
       direction="column"
       alignItems="center"
       justifyContent="center"
       sx={{
         height: "100vh",
       }}
     >
       <CircularProgress />
     </Grid>
   );
 }
 