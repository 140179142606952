import React from "react";
import { MDBContainer, MDBRow, MDBCol, MDBInput } from "mdb-react-ui-kit";
import LoadingButton from "@mui/lab/LoadingButton";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import useAuth from "../../utils/useAuth";
import { Helmet, HelmetProvider } from "react-helmet-async";

export const Login = () => {
  const [username, setUsername] = React.useState("");
  const [password, setPassword] = React.useState("");
  const [status, setStatus] = React.useState("idle");
  const { login } = useAuth();

  const handleSubmit = async (e) => {
    e.preventDefault();
    setStatus("pending");
    try {
      const data = { username, password };
      await login(data);
      setStatus("success");
    } catch (error) {
      console.log(error);
      setStatus("error");
    }
  };

  const renderTooltip = (props) => (
    <Tooltip id="button-tooltip" {...props}>
      Username: K chi ru ; Password: Hindi TaMa EmeRgeNcY!!!
    </Tooltip>
  );

  const loginTitle = "DGallery";

  return (
    <HelmetProvider>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{loginTitle}</title>
      </Helmet>
      <MDBContainer fluid>
        <MDBRow>
          <MDBCol sm="6">
            {/* <div className="d-flex flex-row ps-5 pt-5"> */}
            {/* <MDBIcon fas icon="cat fa-3x me-3" style={{ color: "red" }} /> */}
            {/* </div> */}

            <div className="d-flex flex-column justify-content-center h-custom-2 w-75 pt-4">
              <h3
                className="fw-normal mb-3 ps-5 pb-3"
                style={{ letterSpacing: "1px" }}
              >
                Log in
              </h3>

              <MDBInput
                wrapperClass="mb-4 mx-5 w-100"
                label="Username"
                type="email"
                size="lg"
                autoComplete="off"
                onChange={(e) => setUsername(e.target.value)}
              />
              <MDBInput
                wrapperClass="mb-4 mx-5 w-100"
                label="Password"
                type="password"
                size="lg"
                onChange={(e) => setPassword(e.target.value)}
              />

              <LoadingButton
                className="mb-4 px-5 mx-5 w-100"
                loading={status === "pending"}
                variant="contained"
                onClick={(e) => handleSubmit(e)}
              >
                LOGIN
              </LoadingButton>

              <OverlayTrigger
                placement="top"
                delay={{ show: 250, hide: 400 }}
                overlay={renderTooltip}
              >
                <p className="small mb-5 pb-lg-3 ms-5">
                  <a className="text-muted" href="#!">
                    Need a hint?
                  </a>
                </p>
              </OverlayTrigger>
            </div>
          </MDBCol>

          <MDBCol sm="6" className="d-none d-sm-block px-0">
            <img
              src="https://drew-bday.s3.ap-southeast-1.amazonaws.com/DSC_8476.jpg"
              alt="LoginImg"
              className="w-100"
              style={{ objectFit: "cover", objectPosition: "left" }}
            />
          </MDBCol>
        </MDBRow>
      </MDBContainer>
    </HelmetProvider>
  );
};
