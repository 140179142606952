import { useNavigate } from "react-router-dom";
import { Button } from "@mui/material";

export const NotFound = () => {
  const navigate = useNavigate();
  const goBack = () => navigate(-1);

  return (
    <div>
      <p>404 page not found</p>
      {/* Button */}
      <Button variant="contained" onClick={goBack} sx={{ mt: 6 }}>
        Go Back
      </Button>
    </div>
  );
};
