import { CognitoUser, AuthenticationDetails } from "amazon-cognito-identity-js";
import { UserPool, CognitoIdentityServiceProvider } from "../utils/constants";

class AuthService {
  async login({ username, password }) {
    try {
      const authenticationData = {
        Username: username,
        Password: password,
      };
      const userData = {
        Username: username,
        Pool: UserPool,
      };
      const authenticationDetails = new AuthenticationDetails(
        authenticationData
      );
      const cognitoUser = new CognitoUser(userData);

      return new Promise(function (resolve, reject) {
        cognitoUser.authenticateUser(authenticationDetails, {
          onSuccess: function (session) {
            resolve(getTokensFromSession(session));
          },
          onFailure: function (err) {
            reject(err.code);
          },
          newPasswordRequired: function () {
            reject("User not yet verified");
          },
        });
      });
    } catch (error) {
      console.log(error);
    }
  }

  /**
   *
   * @param {string} token Refresh token
   * @returns {Promise<{ personnel: Object, token: { auth: string, refresh: string}, token_expiry: number } | undefined>}
   */
  async refresh(token) {
    try {
      const params = {
        AuthFlow: "REFRESH_TOKEN_AUTH",
        AuthParameters: {
          REFRESH_TOKEN: token,
        },
        ClientId: "2r187ekecgtovjfkvghb13brj0",
      };

      return new Promise(function (resolve, reject) {
        CognitoIdentityServiceProvider.initiateAuth(
          params,
          function (err, data) {
            if (err) {
              reject("Invalid refresh token");
            } else {
              console.log(data);
              const result = {
                accessToken: data.AuthenticationResult.AccessToken,
                idToken: data.AuthenticationResult.IdToken,
              };
              resolve(result);
            }
          }
        );
      });
      //   const res = await this.axios.get("/auth/refresh", { headers: { "Authorization": `Bearer ${token}` } });
      //   const { data } = res;
      //   return data;
    } catch (error) {
      console.log(error);
    }
  }
}

const getTokensFromSession = (session) => {
  return {
    accessToken: session.getAccessToken().getJwtToken(),
    refreshToken: session.getRefreshToken().getToken(),
  };
};

export default AuthService;
