import React, { useEffect } from "react";
import {
  Route,
  Routes,
  useLocation,
  useNavigate,
  useParams,
} from "react-router-dom";
import "./App.css";
import { TransitionGroup, CSSTransition } from "react-transition-group";
import { Layout } from "./pages/Layout";
import { Login } from "./pages/Login";
import { Home } from "./pages/Home";
import { Gallery } from "./pages/Gallery";
import { RequireAuth } from "./utils/RequireAuth";
import { NotFound } from "./pages/NotFound";
import { AuthProvider } from "./contexts/AuthProvider";

function withRouter(Component) {
  function ComponentWithRouterProp(props) {
    let location = useLocation();
    let navigate = useNavigate();
    let params = useParams();
    return <Component {...props} router={{ location, navigate, params }} />;
  }

  return ComponentWithRouterProp;
}
function _ScrollToTop(props) {
  const { pathname } = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);
  return props.children;
}
const ScrollToTop = withRouter(_ScrollToTop);

export default function App() {
  const location = useLocation();

  return (
    <div>
      <AuthProvider>
        <ScrollToTop>
          <div className="s_c">
            <TransitionGroup>
              <CSSTransition
                key={location.key}
                timeout={{
                  enter: 400,
                  exit: 400,
                }}
                classNames="page"
                unmountOnExit
              >
                <Routes location={location}>
                  <Route exact path="/" element={<Layout />}>
                    <Route path="/login" element={<Login />} />
                    <Route path="*" element={<NotFound />} />
                    <Route element={<RequireAuth />}>
                      <Route exact path="/" element={<Home />} />
                      <Route path="/gallery" element={<Gallery />} />
                    </Route>
                  </Route>
                </Routes>
              </CSSTransition>
            </TransitionGroup>
          </div>
        </ScrollToTop>
      </AuthProvider>
    </div>
  );
}
