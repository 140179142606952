import { createContext, useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import AuthService from "../services/auth.service";
import { useSnackbar } from "notistack";
import AppLoadingIndicator from "../utils/AppLoadingIndicator";
import jwt_decode from "jwt-decode";
import { delay } from "../utils/delay";
const AuthContext = createContext({});

export const AuthProvider = ({ children }) => {
  const navigate = useNavigate();

  const { enqueueSnackbar } = useSnackbar();

  /**
   * Global state to track auth status. This is used to render a loading indicator when the user first loads the app
   * @type ["idle" | "pending" | "error" | "success" ]
   */
  const [status, setStatus] = useState("pending");

  /**
   * @type [{
   * username: string,
   * accessToken: string
   * }]
   */
  const [auth, setAuth] = useState({
    username: null,
    accessToken: null,
  });

  const timerID = useRef(null);

  async function login({ username, password }) {
    const service = new AuthService();
    try {
      const data = await service.login({ username, password });
      if (data === undefined) throw new Error();
      const { accessToken, refreshToken } = data;
      const authData = jwt_decode(accessToken);
      setAuth({
        username: username,
        accessToken: accessToken,
      });

      localStorage.setItem("refreshToken", refreshToken);
      await delay(3000);
      setStatus("idle");
      enqueueSnackbar("Logged in successfully", {
        autoHideDuration: 5000,
        preventDuplicate: true,
        variant: "success",
        anchorOrigin: {
          vertical: "bottom",
          horizontal: "right",
        },
      });
      navigate("/");
      runSilentRefresh(authData.exp);
    } catch (error) {
      let errorMsg = "";
      if (error === "NotAuthorizedException") {
        errorMsg = "Incorrect Username or Password";
      }
      setStatus("error");
      enqueueSnackbar(errorMsg, {
        autoHideDuration: 5000,
        preventDuplicate: true,
        variant: "error",
        anchorOrigin: {
          vertical: "bottom",
          horizontal: "right",
        },
      });
      throw error;
    }
  }

  // silent refresh initializer
  async function initializeSilentRefresh() {
    const service = new AuthService();
    try {
      const refreshToken = localStorage.getItem("refreshToken");
      const data = await service.refresh(refreshToken);
      if (data === undefined) throw new Error();
      const { accessToken, idToken } = data;
      const decodedData = jwt_decode(idToken);
      const authData = jwt_decode(accessToken);
      setAuth({
        username: decodedData.preferred_username,
        accessToken: accessToken,
      });
      localStorage.setItem("refreshToken", refreshToken);
      await delay(3000);
      setStatus("idle");
      navigate("/");
      runSilentRefresh(authData.exp);
    } catch (error) {
      setStatus("error");
      logout();
    }
  }
  // silent refresh runner
  /**
   *
   * @param {number} tokenExpiry
   */
  async function runSilentRefresh(tokenExpiry) {
    const service = new AuthService();
    try {
      const newTimerID = setInterval(async () => {
        const refreshToken = localStorage.getItem("refreshToken");
        const data = await service.refresh(refreshToken);
        if (data === undefined) throw new Error();
        const { accessToken, idToken } = data;
        const decodedData = jwt_decode(idToken);
        setAuth({
          username: decodedData.preferred_username,
          accessToken: accessToken,
        });
        localStorage.setItem("refreshToken", refreshToken);
      }, tokenExpiry);
      timerID.current = newTimerID;
    } catch (error) {
      throw error;
    }
  }
  // logout
  function logout() {
    clearTimer();
    localStorage.setItem("refreshToken", null);
    setAuth({
      username: null,
      accessToken: null,
    });
    navigate("/login");
  }
  // clear timer
  function clearTimer() {
    if (timerID.current === null) return;
    clearInterval(timerID.current);
    timerID.current = null;
  }
  useEffect(() => {
    console.log("Bruh?");
    initializeSilentRefresh();
    return () => clearTimer();
    // eslint-disable-next-line
  }, []);

  return (
    <AuthContext.Provider
      value={{
        login,
        auth,
        setAuth,
        logout,
        clearTimer,
        initializeSilentRefresh,
        runSilentRefresh,
      }}
    >
      {status === "pending" ? <AppLoadingIndicator /> : children}
    </AuthContext.Provider>
  );
};

export default AuthContext;
